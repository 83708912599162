import * as React from 'react'
import { PropsWithChildren } from 'react'

const Exclamation: React.FC<PropsWithChildren<{ color?: string }>> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9.3 44.1"
    xmlSpace="preserve"
  >
    <path
      style={{
        fill: 'black',
      }}
      d="M.5 0h8.2v29.1H.5z"
    />
    <path
      style={{ fill: color ?? 'black' }}
      d="M4.6 44.1c-.8 0-1.6-.2-2.3-.6-.7-.4-1.3-1-1.7-1.7S0 40.3 0 39.5s.2-1.6.6-2.4c.4-.7 1-1.3 1.7-1.7.7-.4 1.5-.6 2.3-.6s1.6.2 2.4.6c.7.4 1.3 1 1.7 1.7s.6 1.5.6 2.4c0 .8-.2 1.6-.6 2.3-.4.7-1 1.3-1.7 1.7s-1.5.6-2.4.6z" />
  </svg>
)

export default Exclamation
