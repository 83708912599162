import { faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { FooterCTA } from '../../components/FooterCTA'
import { Grid } from '../../components/Grid/Grid'
import { SettingsContext } from '../../contexts/settingsContext'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import FeaturedPosts, { Post } from '../FeaturedPosts'
import Spacer from '../FlexibleContent/slices/Spacer'
import ColumnContact from './Columns/ColumnContact'
import ColumnDefault, { ColumnDefaultProps } from './Columns/ColumnDefault'
import classes from './Footer.module.scss'

export type FooterCallToActionProps = {
  cta_title: string
  cta_desc: string
  cta_variant: 'circle' | 'twoCol'
  cta_btn_label?: string
  component: 'footer_cta' | 'footer_featured_posts'
  is_darkmode: boolean
  cta_btn_url?: {
    cached_url?: string
  }
  cta_background_image: {
    filename: string
    alt?: string
    title?: string
  }
  posts: Post[]
  is_load_more: boolean
  is_freshly_published: boolean
  alt_bg: boolean
}[]

export type FooterContainerProps = {
  component: 'footer_container'
  slug: {
    cached_url: string
    url: string
  }
}[]

export type FooterMenuProps = {
  content: {
    style: string
    columns: ColumnDefaultProps
  }
  full_slug: string
}[]

export type FooterProps = {
  static_footer: boolean
  cta?: FooterCallToActionProps
}

export const Footer: React.FC<PropsWithChildren<FooterProps>> = ({ cta, static_footer }) => {
  const {
    footer_columns,
    privacy_link,
    conditions_link,
    facebook,
    linkedin,
    instagram,
    twitter,
  } = React.useContext(SettingsContext)

  const isMobile = useMediaQuery('(max-width: 1024px)')
  const containerClasses = [classes.featuredContainer]
  const fixedClasses = [classes.parraFooter]
  const ctaFooterClasses = [classes.footer]
  const [isSafari, setIsSafari] = useState<boolean>(false)

  // @ts-ignore
  useEffect(() => {
    if (typeof navigator !== 'undefined' && /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      setIsSafari(true)
    }
  }, [isSafari])


  if (cta?.[0]?.alt_bg) containerClasses.push(classes.featuredPostWrapper)

  if (cta?.[0]) ctaFooterClasses.push(classes.footer__hasCta)

  if (!static_footer && !isSafari) fixedClasses.push(classes.fixedFooter)

  return (
    <>
      {cta?.[0]?.component === 'footer_cta' && (
        <FooterCTA
          title={cta?.[0]?.cta_title}
          variant={cta?.[0]?.cta_variant}
          isDarkMode={cta?.[0]?.is_darkmode}
          content={cta?.[0]?.cta_desc}
          linkBtnText={cta?.[0]?.cta_btn_label}
          linkURL={cta?.[0].cta_btn_url?.cached_url ? cta?.[0]?.cta_btn_url?.cached_url : '#'}
          imageURL={cta?.[0]?.cta_background_image?.filename}
        />
      )}
      {cta?.[0]?.component === 'footer_featured_posts' && (
        <div className={classnames(containerClasses)}>
          <FeaturedPosts slice={{ posts: cta?.[0]?.posts, alt_bg: false, is_freshly_published: cta?.[0]?.is_freshly_published, is_load_more: cta?.[0]?.is_load_more, footer: true }} />
        </div>
      )}
      {cta && cta?.length < 1 && (
        <div style={{ zIndex: 1 }}>
          <Spacer slice={{ _uid: 'sjs', component: 'spacer', hr: false }} />
        </div>
      )}
      <>
        {footer_columns && footer_columns.length > 0 &&
          <footer className={classnames(ctaFooterClasses)}>
            <div className={classes.footerTopCurve}></div>
            <div className={classnames(classes.footerContainer)}>
              <Grid container className={classnames(fixedClasses)}>
                <div className={classes.footerColumn}>
                  <div className={classes.topRow}>
                    <ColumnContact />
                    {footer_columns.map(({ heading, content }, index) => (
                      <ColumnDefault heading={heading} content={content} key={index} />
                    ))}
                  </div>

                  <div className={classes.copyrightContainer}>
                    <div className={classnames(classes.footer__copyright, classes.footer__contained)}>
                      {isMobile && (
                        <div className={classes.mobileIcons}>
                          {facebook !== '' && <a aria-label="Facebook" target="_blank" rel="noreferrer" href={facebook}><FontAwesomeIcon icon={faFacebookF} /></a>}
                          {instagram !== '' && <a aria-label="Instagram" target="_blank" rel="noreferrer" href={instagram}><FontAwesomeIcon icon={faInstagram} /></a>}
                          {linkedin !== '' && <a aria-label="LinkedIn" target="_blank" rel="noreferrer" href={linkedin}><FontAwesomeIcon icon={faLinkedinIn} /></a>}
                          {twitter !== '' && <a aria-label="Twitter" target="_blank" rel="noreferrer" href={twitter}><FontAwesomeIcon icon={faTwitter} /></a>}
                        </div>
                      )}
                      <p className={classes.copyRightText}>&copy; {new Date().getFullYear()} Atomix</p>
                      <p>
                        {!isMobile && <span>|</span>}
                        <a className={classes.privacyTerms} href={`/${privacy_link?.cached_url}`}>Privacy policy</a>
                        <span>|</span>
                        <a className={classes.privacyTerms} href={`/${conditions_link?.cached_url}`}>Terms and Conditions</a></p>
                    </div>
                  </div>
                </div>
              </Grid>
            </div>
          </footer>
        }
      </>
    </>
  )
}

export default Footer
