import classnames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { CustomLink } from '../CustomLink'
import { Grid, GridProps } from '../Grid'
import classes from './TwoColumnCTA.module.scss'
import Exclamation from './icons/exclamation'
import Question from './icons/question'

export type TwoColumnProps = Omit<GridProps, 'item'> & {
  className?: string
  isDarkMode?: boolean
  leftColumnBody?: string
  rightColumnBody: string
  rightActionText?: string
  rightActionHref?: string
  icon?: string
  icon_colour?: string
}

export const TwoColumnCallAction: React.FC<PropsWithChildren<TwoColumnProps>> = ({ isDarkMode, leftColumnBody, rightColumnBody,rightActionText, rightActionHref , icon, icon_colour, className }) => {
  const twoColClasses = [classes.twoColumn, className]

  if (isDarkMode) twoColClasses.push(classes['darkMode'])

  return (
    <Grid
      className={classnames(twoColClasses)} container>
      <Grid row className={classnames(classes.leftContent)}>
        <Grid className={classnames(classes.leftColumnBody, className)} item>
          <h2 style={{ width: '100%' }} >
            {leftColumnBody}
            {renderIcon(icon, icon_colour)}
          </h2>
        </Grid>
        <Grid item className={classnames(classes.rightContent)}>
          <div className={classnames(classes.rightColumnBody, className)} style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: rightColumnBody }} />
          <div className={classes.rightActionButton} style={{ width: '100%' }}>
            <CustomLink
              href={rightActionHref || '#'}
              variant={'secondary-with-arrow'}
              inverseColor={!isDarkMode}
            >
              {rightActionText}
            </CustomLink>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}


export const renderIcon = (type?: string, colour?: string) => {
  switch (type) {
  case 'question':
    return (
      <span className={classes.question}><Question color={colour} /></span>
    )
  case 'dot':
    return (
      <span style={{ color: colour }}>.</span>
    )
  case 'exclamation':
    return (
      <span className={classes.exclamation}><Exclamation color={colour} /></span>
    )
  default:
    return
  }
}
