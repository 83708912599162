import React from 'react'
import classes from '../Footer.module.scss'
import renderRichText from '../../../utils/richTextResolver'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { Accordion } from '../../../components/Accordion'
import { ISbRichtext } from 'storyblok-js-client'

export type ColumnDefaultProps = {
  heading: string
  content: ISbRichtext
}

export const ColumnDefault: React.VFC<ColumnDefaultProps> = (props) => {
  const isMobile = useMediaQuery('(max-width: 1023px)')

  props.content.content?.map((item) => {
    // @ts-ignore
    if (item?.content[0].marks[0].attrs.href.endsWith('/')) {
      // @ts-ignore
      item.content[0].marks[0].attrs.href = item?.content?.[0]?.marks?.[0]?.attrs.href.slice(0,-1)
    }
  })

  return isMobile ? (
    <div className={classes['footer__column-default']} >
      <div className={classes['footer__column-default__accordion']}>
        <Accordion>
          <Accordion panel footer={true} i={1}>
            <Accordion footer={true} title>
              {props.heading}
            </Accordion>
            <Accordion footer={true} content>
              <section
                className={classes['footer__column-default__content']}
                dangerouslySetInnerHTML={{ __html: renderRichText(props.content) }}
              />
            </Accordion>
          </Accordion>
        </Accordion>
      </div>
    </div>
  ) : (
    <div className={classes['footer__column-default']} >
      {props.heading && (
        <h2 className={classes['footer__column-default__heading']}>{props.heading}</h2>
      )}
      {props.content && (
        <section
          className={classes['footer__column-default__content']}
          dangerouslySetInnerHTML={{ __html: renderRichText(props.content) }}
        />
      )}
    </div>
  )
}

export default ColumnDefault
