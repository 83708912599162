/* eslint-disable no-inner-declarations */
import classnames from 'classnames'
import React, { PropsWithChildren, useEffect, useRef } from 'react'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { IMAGE_FILTER } from '../../utils/detectWebPSupported'
import { CTACircle } from '../CTACircle'
import { TwoColumnCallAction } from '../TwoColumnCTA'
import styles from './FooterCTA.module.scss'

export type FooterCTAProps = {
  imageURL: string
  variant: 'circle' | 'twoCol'
  title: string
  content: string
  linkURL?: string
  linkBtnText?: string
  isDarkMode?: boolean
}

export const FooterCTA: React.FC<PropsWithChildren<FooterCTAProps>> = ({
  imageURL,
  variant,
  title,
  content,
  linkURL,
  linkBtnText,
  isDarkMode,
}) => {
  const parallax = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)
  const isMobile = useMediaQuery('(max-width: 1440px')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isChromium = window.chrome
      const winNav = window.navigator
      const vendorName = winNav.vendor
      const isOpera = typeof window.opr !== 'undefined'
      const isIEedge = winNav.userAgent.includes('Edg')
      const isIOSChrome = winNav.userAgent.match('CriOS')

      if ( !isIOSChrome && !!window?.chrome && (  isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false)) {
        const scrollHandler = () => {
          const element = parallax?.current
          const footerWrap = footerRef?.current

          if (element && footerWrap && !isMobile) {
            const offset = footerWrap.getBoundingClientRect().top

            element.style.backgroundPositionY = offset * 0.2 + 'px'
          }

          if (element && isMobile) {
            element.style.backgroundPositionY = '0'
          }
        }

        window.addEventListener('scroll', scrollHandler)

        return () => window.removeEventListener('scroll', scrollHandler)
      } else return
    } else return
  }, [isMobile])

  return (
    <div ref={footerRef} className={classnames(styles.ctaWrapper, styles[`variant-${variant}`])}>
      <div className={classnames(styles.ctaInnerWrapper)}>
        <div ref={parallax} className={classnames(styles.contentWrapper)} style={{ backgroundImage: `url("${imageURL}${IMAGE_FILTER}")` }}>
          {variant === 'twoCol' ? (
            <TwoColumnCallAction
              leftColumnBody={title}
              rightColumnBody={content}
              rightActionText={linkBtnText}
              rightActionHref={linkURL ? `${linkURL}` : '#'}
              isDarkMode={isDarkMode}
            />
          ) : (
            <CTACircle
              title={title}
              content={content}
              rightActionText={linkBtnText}
              rightActionHref={linkURL ? `${linkURL}` : '#'}
            />
          )}
        </div>
      </div>
    </div>
  )
}
