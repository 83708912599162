import classnames from 'classnames'
import React, { PropsWithChildren } from 'react'
import AtomixIcon from '../../components/icons/AtomixIcon'
import { CustomLink } from '../CustomLink'
import { GridProps } from '../Grid'
import classes from './CTACircle.module.scss'

export type CTACircleProps = Omit<GridProps, 'item'> & {
  title?: string
  content: string
  rightActionText?: string
  rightActionHref?: string
}

export const CTACircle: React.FC<PropsWithChildren<CTACircleProps>> = ({
  title,
  content,
  rightActionHref,
  rightActionText }) => {
  return (
    <div className={classnames(classes.ctaCircle)} >
      <div className={classnames(classes.contentBody)}>
        <div className={classnames(classes.innerWrapper)}>
          <h2>{title}</h2>
          <div className={classnames(classes.content)} dangerouslySetInnerHTML={{ __html: content }} />
          <div className={classnames(classes.btnWrapper)}>
            <CustomLink
              href={rightActionHref ? `${rightActionHref}` : '#'}
              variant={'primary-with-arrow'}
            >
              <p className={classes.linkText}>{rightActionText}</p>
              <AtomixIcon className={classes['arrow']} iconCode={'upArrowFooterCta'} />
            </CustomLink>
          </div>
        </div>
      </div>
    </div>
  )
}
