import classnames from 'classnames'
import { FlexibleContent } from '../../../../components/FlexibleContent'
import { withSlice } from '../../../SliceComponent'
import { FlexibleContentSlice } from '../index'
import classes from './spacer.module.scss'

export type SpacerProps = {
  hr?: boolean
  size?: 'large' | 'medium' | 'small'
  hidden_mobile?: boolean
}

export const Spacer: FlexibleContentSlice<'spacer', SpacerProps> = ({ slice }) => {
  const { hr, size, hidden_mobile } = slice
  const spacerClasses = [classes.spacer]
  const hrClasses = [classes.hr]

  if (hidden_mobile) spacerClasses.push(classes.hiddenMobile)

  switch (size) {
  case 'large':
    if (!hr) spacerClasses.push(classes.spacerLarge)
    hrClasses.push(classes.hrLarge)
    break
  case 'medium':
    if (!hr) spacerClasses.push(classes.spacerMedium)
    hrClasses.push(classes.hrMedium)
    break
  case 'small':
    if (!hr) spacerClasses.push(classes.spacerSmall)
    hrClasses.push(classes.hrSmall)
    break
  default:
    if (!hr) spacerClasses.push(classes.spacerLarge)
    hrClasses.push(classes.hrLarge)
    break
  }
    
  return (
    <FlexibleContent column >
      <div className={classnames(spacerClasses)}>
        {hr && (
          <hr className={classnames(hrClasses)} />
        )}
      </div>
    </FlexibleContent>
  )
}

export default withSlice(Spacer)
