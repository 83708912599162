import { faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Logo } from '../../../components/Logo'
import { getHomepageUrl, SettingsContext } from '../../../contexts/settingsContext'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import classes from '../Footer.module.scss'

export const ColumnContact: React.VFC = () => {
  const {
    homepage,
    address_line_1,
    address_line_2,
    contact_phone,
    contact_email,
    facebook,
    linkedin,
    instagram,
    twitter,
  } = React.useContext(SettingsContext)
  const isMobile = useMediaQuery('(max-width: 1024px)')

  return (
    <div className={classes['footer__column-contact']}>
      <Logo
        className={classes['footer__column-contact__logo']}
        href={homepage ? getHomepageUrl(homepage) : '/'}
        title="Atomix Logo"
        inverse
      />
      <div className={classes['footer__column-contact__mobrow']}>
        <div className={classes['footer__column-contact__address']}>
          <p>{address_line_1}</p>
          <p>{address_line_2}</p>
        </div>
        <div className={classes['footer__column-contact__address']}>
          <p><a href={'tel:' + contact_phone}>{contact_phone}</a></p>
          <p><a href={'mailto:' + contact_email}>{contact_email}</a></p>
        </div>
      </div>
      {!isMobile && (
        <div className={classes['footer__column-social__icons']}>
          {facebook !== '' && <a aria-label="Facebook" target="_blank" rel="noreferrer" href={facebook}>
            <FontAwesomeIcon icon={faFacebookF} />
          </a>}
          {instagram !== '' && <a aria-label="Instagram" target="_blank" rel="noreferrer" href={instagram}>
            <FontAwesomeIcon icon={faInstagram} />
          </a>}
          {linkedin !== '' && <a aria-label="LinkedIn" target="_blank" rel="noreferrer" href={linkedin}>
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>}
          {twitter !== '' && <a aria-label="Twitter" target="_blank" rel="noreferrer" href={twitter}>
            <FontAwesomeIcon icon={faTwitter} />
          </a>}
        </div>
      )}
    </div>
  )
}

export default ColumnContact
