import * as React from 'react'
import { PropsWithChildren } from 'react'

const Question: React.FC<PropsWithChildren<{color?: string}>> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28.8 44.6"
    xmlSpace="preserve"
  >
    <path
      d="M10.8 19.9c1.8 0 3.6-.2 5.4-.6 1.3-.3 2.5-1 3.4-2.1.9-1.2 1.3-2.7 1.2-4.2 0-1.1-.2-2.3-.8-3.3-.5-.9-1.3-1.7-2.2-2.2-1.1-.5-2.2-.8-3.4-.8s-2.4.2-3.4.8C10 8 9.3 8.8 8.7 9.8c-.5 1.1-.7 2.3-.7 3.4H0c0-2.4.6-4.8 1.8-6.9 1.2-2 3-3.6 5.1-4.7C9.3.5 11.8 0 14.4 0c2.5 0 5.1.5 7.4 1.6 2.1 1 3.9 2.5 5.1 4.5 1.3 2 1.9 4.4 1.9 6.8.1 2.3-.5 4.5-1.6 6.5-1 1.6-2.4 2.9-4 3.7-1.6.8-3.3 1.4-5 1.6v6.1h-7.4V19.9z"
      style={{
        fill: 'black',
      }}
    />
    <path style={{ fill: color ?? 'black' }} d="M14.7 44.6c-.8 0-1.6-.2-2.4-.6-.7-.4-1.3-1-1.7-1.7S10 40.8 10 40s.2-1.6.6-2.4c.4-.7 1-1.3 1.7-1.7s1.5-.6 2.4-.6c.8 0 1.6.2 2.3.6.7.4 1.3 1 1.7 1.7.4.7.6 1.5.6 2.4 0 .8-.2 1.6-.6 2.3s-1 1.3-1.7 1.7-1.5.6-2.3.6z" />
  </svg>
)

export default Question
